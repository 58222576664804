// コピペ from https://v2.chakra-ui.com/getting-started/remix-guide
import { CacheProvider } from '@emotion/react';
import { RemixBrowser } from '@remix-run/react';
import React, { useState } from 'react';
import * as ReactDOM from 'react-dom/client';

import { I18nextProvider } from 'react-i18next';
import { ClientStyleContext } from './context';
import createEmotionCache, { defaultCache } from './createEmotionCache';
import i18n from './i18n';

import * as Sentry from '@sentry/remix';

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});

interface ClientCacheProviderProps {
  children: React.ReactNode;
}

function ClientCacheProvider({ children }: ClientCacheProviderProps) {
  const [cache, setCache] = useState(defaultCache);

  function reset() {
    setCache(createEmotionCache());
  }

  return (
    <ClientStyleContext.Provider value={{ reset }}>
      <CacheProvider value={cache}>{children}</CacheProvider>
    </ClientStyleContext.Provider>
  );
}

ReactDOM.hydrateRoot(
  document,
  <ClientCacheProvider>
    <I18nextProvider i18n={i18n}>
      <RemixBrowser />
    </I18nextProvider>
  </ClientCacheProvider>
);
